<template>
	<div class="center">
		<van-nav-bar
		:class="{'nav_bar_colorcenter':scoll<100,'nav_bar_colorcenter2':scoll>=100}"
		title="我的"
		fixed
		z-index="999" 
		:border="false"
		placeholder
		>
	</van-nav-bar>
		<!-- 背景图 -->
		<div class="center_bj">
		<img src="http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_centerbj.png" />
		</div>
		<div class="center_top">
			<van-image class="center_avatar" round :src="serverUrl(userinfo?.avatar)" fit="cover"/>
				<div class="center_user">
					<div class="">
						{{userinfo?.username}}
					</div>
					<div class="">
						{{userinfo?.contact}}
					</div>
				</div>
			<div class="center_srtup" @click="gopath('/useredit')">
				<span>编辑</span>
				<img src="@/assets/center/icon_setup.png" >
			</div>
		</div>
		<div v-if="isstore_auth == 0" class="ddd"></div>
		<div class="center_middle" v-if="isstore_auth == 1">
			<div class="center_middle_nav">
				<div class="center_middle_setup">
					设置
				</div>
				<div class="center_middle_list">
					<div class="center_middle_tab" @click="gopath('/stores-information')">
						<img src="@/assets/center/icon_setupa.png" alt="" />
							<span>门店信息</span>
					</div>
					<div class="center_middle_tab">
						<img src="@/assets/center/icon_setupb.png" alt="" @click="gopath('/test-drive-route')" />
							<span>试驾路线</span>
					</div>
					<div class="center_middle_tab">
						<img src="@/assets/center/icon_setupc.png" alt="" @click="gopath('/test-drive-invitations')" />
							<span>试驾邀请</span>
					</div>
					<div class="center_middle_tab">
						<img src="@/assets/center/icon_setupd.png" alt="" @click="gopath('/test-drive-type')" />
							<span>试驾车型</span>
					</div>
					<div class="center_middle_tab">
						<img src="@/assets/center/icon_setupe.png" alt="" @click="gopath('/test-drive-agreements')" />
							<span>试驾协议</span>
					</div>
				</div>
			</div>
		</div>
		
		<!-- tab菜单 -->
		<!-- tab菜单 -->
		<!-- <div id="demo" :style="{'top': navBarHeight + 'px','padding':'30px 0 0'}" :class="{'isfixed':isfixed}"> -->
			
			<van-sticky @scroll="changetop" :offset-top="40">
				<MenuTab :list="menuTabList" :listChecked="menuTabChecked" v-on:edit="handMenuTab"></MenuTab>
			
			<!-- 搜索 -->
				<SearchInput
				:placeholder="searchInputPlaceholder"
				:search="searchInputValue"
				v-on:edit="handSearchInput"
			></SearchInput>
		</van-sticky>

		<!-- </div> -->
		<!-- <div :class="{'istop':isfixed}">
			
		</div> -->
			<!-- 试驾列表 -->
			<van-list
				v-model="loading"
				:finished="finished"
				style="font-family:HYQiHeiY3-55;"
				finished-text="没有更多了"
				@load="onLoad"
			>
				<div v-show="menuTabChecked == 'list'" class="scsjs">
					<div class="scsj" v-for="item,index in scsjList" :key="index" @click="handTrial(item)">
						<div class="scsj__left">
							<div class="scsj__user">
								{{item.name}} {{item.mobile}}
							</div>
							<div class="scsj__time">
								{{item.finishtime}}
							</div>
							<div class="scsj__staffs">
								<div class="scsj__staffs__staff" v-for="itemUser in item.user" :key="itemUser.id">
									<van-image class="center_avatar" round :src="itemUser.avatar" fit="cover"/>
									<span>{{itemUser.username}}</span>
								</div>
							</div>
						</div>
						<div class="scsj__right">
							<img src="@/assets/common/icon_right.png">
						</div>
						<div class="scsj__suprcruise" v-if="item.if_cruise == 1">
							<img src="@/assets/common/icon_suprcruise.png">
						</div>
					</div>
				</div>
				<div v-if="scsjList.length == 0 && menuTabChecked == 'list'" class="custom-image"><img src="@/assets/user_edit/icon_kong.png" alt=""><div>暂无数据</div></div>
			
			<!-- 邀请列表 -->
				<div v-show="menuTabChecked == 'inviter'" class="inviters">
					<div class="inviter" v-for="item,index in inviterList" :key="index" @click="handInviter(item)">
						<div class="inviter__left">
							<div class="inviter__user">
								<span>{{item.customer_name}}</span><span>{{item.customer_mobile}}</span>
							</div>
							<div class="inviter__car">
								凯迪拉克 {{item.model_name}}
							</div>
							<div class="inviter__time">
								{{item.appointment_time}}
								<div v-if="item.if_check == 1" class="inviter__time__botton">
									已确认
								</div>
							</div>
						</div>
						<div class="inviter__right">
							<img src="@/assets/common/icon_right.png">
						</div>
					</div>
				</div>

				<div v-if="inviterList.length == 0 && menuTabChecked == 'inviter'" class="custom-image"><img src="@/assets/user_edit/icon_kong.png" alt=""><div>暂无数据</div></div>
			</van-list>
	</div>
</template>
 
<script>
import MenuTab from '@/components/menutab.vue'
import SearchInput from '@/components/searchinput.vue'
import { getDateTime2, getDateTime3 } from '@/utils/utils.js'

export default {
	components: {
		MenuTab,
		SearchInput
	},
	data() {
		return {
			userinfo:{},
			
			scrollTop: 0,
			// tab栏
			menuTabList: [{
				num: 0,
				name: '试驾记录',
				value: 'list'
			}, {
				num: 0,
				name: '试驾邀请',
				value: 'inviter'
			}],
			menuTabChecked: 'list',
			
			isfixed:false,
			searchInputValue: '',
			scsjList: [],
			scsjStatus: 'more',
			scsjPage: 1,
			scsjLimit: 10,
			scsjTotal: 0,
			inviterList: [],
			inviterStatus: 'more',
			inviterPage: 1,
			inviterLimit: 10,
			inviterTotal: 0,
			isstore_auth:'0',
			// navBarHeight: getApp().globalData.navBarHeight,
			top:'',
			// 分页
			loading: false,
      finished: false,
			scoll:0,
		};
	},
	computed: {
		searchInputPlaceholder() {
			return {
				list: '请输入客户名称、电话查找试驾记录',
				inviter: '请输入客户名称、电话查找试驾邀请'
			}[this.menuTabChecked]
		}
	},
	mounted() {
		localStorage.removeItem('ticker')
		this.geiuserinfo()
		this.getScsjList()
		this.getInviterList()
	},
	methods: {
		changetop(e,v){
			console.log(e.scrollTop)
			this.scoll = e.scrollTop
			this.isFixed = e,v
		},
		isToday(str){
			let d = new Date(parseInt(str *1000)).setHours(0, 0, 0, 0);
			let today = new Date().setHours(0, 0, 0, 0);
			let obj = {
				0: '今日',
				'-86400000': '咋日',
			};
			return obj[d - today] || false;
		},
		async onLoad(){
			if(this.menuTabChecked == 'list'){
				if(this.scsjPage == 1) return this.loading = false;
				const {data: result} = await this.$http.post('/api/scsj/search_user_scsj_log', {
				page: this.scsjPage,
				num: this.scsjLimit,
				words: this.searchInputValue,
				store_id:0
			})
				if (result.code == 1) {
					this.scsjPage=this.scsjPage+1
					this.scsjTotal = result.data.total
					this.menuTabList[0].num = this.scsjTotal
					result.data.res.forEach(v => {
					v.finishtime = getDateTime2(v.finishtime)
						v.user.forEach(vu => {
							vu.avatar = this.serverUrl(vu.avatar)
						})
						this.scsjList.push(v)
					})
				}
			 // 加载状态结束
			 this.loading = false;
			 // 数据全部加载完成
			 if (this.scsjList.length >= this.scsjTotal) {
					this.finished = true;
				}
			}else{
				if(this.inviterPage == 1) return this.loading = false;
				
					const {data: result} = await this.$http.post('/api/scsj/get_user_invitation_list', {
					page: this.inviterPage,
					number: this.inviterLimit,
					type_id: 1,
					words: this.searchInputValue
				})
				if (result.code == 1) {
					this.inviterPage=this.inviterPage+1
					this.inviterTotal = result.data.total
					this.menuTabList[1].num = this.inviterTotal
					result.data.res.forEach(v => {
						const ss = this.isToday(v.appointment_time)
						if(ss){
							v.appointment_time = getDateTime3(v.appointment_time)
							v.appointment_time = v.appointment_time.slice(v.appointment_time.indexOf(' '))
							v.appointment_time = ss + ' ' + v.appointment_time
						}else{
							v.appointment_time = getDateTime3(v.appointment_time)
						}
						this.inviterList.push(v)
					})
				}
				// 加载状态结束
				this.loading = false;
			 // 数据全部加载完成
			 if (this.inviterList.length >= this.inviterTotal) {
					this.finished = true;
				}
			}
		},
		// 试驾详情
		handTrial(e) {
			this.$router.push({
				path: `/trial?number=${e.number}`
			})
		},
		// 试驾邀请
		handInviter(e) {
			this.$router.push({
				path: `/trial-invitation?id=${e.id}`
			})
		},
		// 点击tab菜单
		handMenuTab(e) {
			this.menuTabChecked = e
			this.inviterPage = 1
			this.scsjPage = 1
			this.inviterStatus = 'more'
			this.scsjList = []
			this.inviterList = []
			this.finished = false
			if(this.menuTabChecked == 'list'){
				this.getScsjList()
			}else{
				this.getInviterList()
			}
		},
		// 路由跳转
		gopath(url){
			this.$router.push({path:url})
		},
		
		// 用户详情
		async geiuserinfo(){
			const {data:res} = await this.$http.post('/api/user/get_userinfo_1_5_1')
			if(res.code == 1){
				this.userinfo = res.data.res
				this.isstore_auth = res.data.res.store_auth
				localStorage.setItem('store_auth',res.data.res.store_auth)
			}
		},
		
		// 搜索内容修改
		handSearchInput(e) {
			this.searchInputValue = e.trim()
			if (this.searchInputValue.length == 0) return
			this.$router.push({
				path: `/search?type=${this.menuTabChecked}&words=${this.searchInputValue}&categories=individual`
			})
		},
		
		// 试驾记录
		async getScsjList(e) {
			const {data: result} = await this.$http.post('/api/scsj/search_user_scsj_log', {
				page: this.scsjPage,
				num: this.scsjLimit,
				words: this.searchInputValue,
				store_id:0
			})
			if (result.code == 1) {
				this.scsjTotal = result.data.total
				this.scsjPage=this.scsjPage+1
				this.menuTabList[0].num = this.scsjTotal
				result.data.res.forEach(v => {
					v.finishtime = getDateTime2(v.finishtime)
					v.user.forEach(vu => {
						vu.avatar = this.serverUrl(vu.avatar)
					})
					this.scsjList.push(v)
				})
			}
		},
		
		// 邀请记录
		async getInviterList(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_user_invitation_list', {
				page: this.inviterPage,
				number: this.inviterLimit,
				type_id: 1,
				words: this.searchInputValue
			})
			if (result.code == 1) {
				this.inviterPage=this.inviterPage+1
				this.inviterTotal = result.data.total
				this.menuTabList[1].num = this.inviterTotal
				result.data.res.forEach(v => {
						const ss = this.isToday(v.appointment_time)
						if(ss){
							v.appointment_time = getDateTime3(v.appointment_time)
							v.appointment_time = v.appointment_time.slice(v.appointment_time.indexOf(' '))
							v.appointment_time = ss + ' ' + v.appointment_time
						}else{
							v.appointment_time = getDateTime3(v.appointment_time)
						}
						this.inviterList.push(v)
					})
			}
		},
	}
}
</script>

<style lang="scss">
.center{
	position: relative;
	padding-bottom: 170px;
	.center_bj{
		height: 480px;
		width: 750px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		img{
			width: 100%;
		}
	}
}
.isfixed{
	width: 100%;
	position: fixed;
	background-color: rgba(25, 25, 25, 1);
	z-index: 999;
}
// .ispadd{
// 	padding: 0 40px;
// }
.center_top{
	height: 230px;
	padding: 40px 30px;
	position: relative;
	display: flex;
	align-items: center;
	color: #fff;
	.center_srtup{
		position: absolute;
		right: 40px;
		top: 40px;
		display: flex;
		align-items: center;
		span{
			font-weight: normal;
			font-family: HYQiHeiY3-55;
			font-size: 36px;
		}
		img{
			margin-left: 10px;
			height: 36px;
			width: 36px;
		}
	}
	.center_user{
		margin-left: 20px;
		div:nth-child(1){
			font-size: 40px;
			font-weight: normal;
			margin-bottom: 20px;
			font-family: HYQiHeiY3-55;
		}
		div:nth-child(2){
			font-size: 24px;
			font-weight: 400;
			font-family: Regular;
		}
	}
	.center_avatar{
		width: 152px;
		height: 152px;
		border: 1px solid #919191;
		border-radius: 50%;
		
	}
}

.center_middle{
	width: 690px;
	padding: 0 30px 40px;
	position: relative;
	
	.center_middle_nav{
		padding: 36px;
		background: linear-gradient(135deg, #2C28E8 0%, #237DFE 100%);
		border-radius: 12px;
		height: 202px;
		color: #ffffff;
		.center_middle_setup{
			text-align: left;
			font-weight: normal;
			font-family: HYQiHeiY3-55;
			font-size: 36px;
		}
		.center_middle_list{
			display: flex;
			.center_middle_tab{
				flex: 1;
				display: flex;
				justify-content: center;
				flex-flow: column;
				align-items: center;
				margin-top: 50px;
				img{
					width: 64px;
					height: 64px;
				}
				span{
					font-size: 28px;
					font-family: HYQiHeiY3-45;
				}
			}
		}
	}
}

.scsjs{
	width: 100%;
	box-sizing: border-box;
	margin-top: 20px;
	padding: 0 32px 30px;
	.scsj{
		position: relative;
		margin-bottom: 30px;
		box-sizing: border-box;
		padding: 20px 32px;
		width: 100%;
		background: #373C46;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__user{
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 36px;
		}
		&__time{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
		}
		&__staffs{
			margin-top: 15px;
			display: flex;
			&__staff{
				font-size: 26px;
				font-family: HYQiHeiY3-45;
				color: #ECECEC;
				height: 40px;
				display: flex;
				align-items: center;
				margin-right: 20px;
				.center_avatar{
					width: 40px;
					height: 40px;
					border-radius: 50%;
					margin-right: 10px;
				}
			}
		}
		&__right{
			img{
				width: 40px;
				height: 32px;
			}
		}
		&__suprcruise{
			position: absolute;
			right: 0;
			top: 0;
			img{
				width: 118px;
				height: 118px;
			}
		}
	}
	.scsj:last-child{
		margin-bottom: 0;
	}
}
.inviters{
	margin-top: 20px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 32px 30px;
	.inviter{
		margin-bottom: 30px;
		padding: 20px 32px;
		box-sizing: border-box;
		width: 100%;
		background: #373C46;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__user{
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #9B9B9B;
			line-height: 36px;
			span:first-child{
				color: #fff;
				margin-right: 10px;
			}
		}
		&__car{
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
		}
		&__time{
			display: flex;
			align-items: center;
			margin-top: 15px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			color: #fff;
			line-height: 28px;
			&__botton{
				margin-left: 36px;
				width: 104px;
				height: 48px;
				background: #C8002B;
				border-radius: 8px;
				line-height: 48px;
				text-align: center;
				font-size: 26px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #ECECEC;
			}
		}
		&__right{
			img{
				width: 40px;
				height: 32px;
			}
		}
	}
	.inviter:last-child{
		margin-bottom: 0;
	}
}
.istop{
	height: 108px;
}
.ddd{
	height: 140px;
}
.nav_bar_colorcenter{
	font-family: HYQiHeiY3-45;
	background-color: #191919;
	color: #ffffff !important;
	.van-nav-bar__title{
	color: #ffffff !important;
	background-color: rgba(25, 25, 25, 0);
	}
	.van-nav-bar__text{
		color: #ffffff;
	}
	.van-nav-bar .van-icon {
		color: #ffffff;
	}
	.van-nav-bar{
		box-sizing: border-box;
		background-color: rgba(25, 25, 25, 0);
	} 
}

.nav_bar_colorcenter2{
	font-family: HYQiHeiY3-45;
	background-color: #191919;
	color: #ffffff !important;
	.van-nav-bar__title{
	color: #ffffff !important;
	background-color: rgba(25, 25, 25, 0);
	}
	.van-nav-bar__text{
		color: #ffffff;
	}
	.van-nav-bar .van-icon {
		color: #ffffff;
	}
	.van-nav-bar{
		background-color:#191919
		// background-color: rgba(25, 25, 25, 1);
	} 
}
.backcolor{
	
}

.backcolor2{
	background-color: #191919 !important;
}
</style>
